//Button group
.btn-group, .input-group-btn, .input-group-prepend, .input-group-append {

	> .btn {

		//Fix the next button border color when the next element is :focus
		&.btn-secondary:hover + .btn,
		&.btn-secondary.active + .btn {
			border-left-color: darken($btn-secondary-border, 13%);
		}

		&.btn-primary {
			@include colored-btn-group($btn-primary-bg);
		}

		&.btn-success {
			@include colored-btn-group($btn-success-bg);
		}

		&.btn-warning {
			@include colored-btn-group($btn-warning-bg);
		}

		&.btn-danger {
			@include colored-btn-group($btn-danger-bg);
		}

		&:last-of-type:not(.btn-secondary){
			border-right-color: transparent;

			&:hover{
				border-right-color: transparent;
			}
		}

		& + .dropdown-toggle{
			padding-left: 7px;
			padding-right: 7px;
		}
	}

	&:first-child{
		border-right-color: transparent;
	}

	&:last-child{
		border-right-color: transparent;
	}

	&.open {

		> .btn {

			&.btn-primary,
			&.btn-success,
			&.btn-warning,
			&.btn-danger {

				&:hover{
					box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.1);
				}
			}

			& + .btn-primary,
			& + .btn-success,
			& + .btn-warning,
			& + .btn-danger {
				border-left-color: rgba(0, 0, 0, 0.15);
			}
		}
	}
}

// Justified button groups
// ----------------------

.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;

  .btn,
  .btn-group {
    float: none;
    display: table-cell;
    width: 1%;
    .btn {
      width: 100%;
    }
    .dropdown-menu {
      left: auto;
    }
  }
  
  > .btn {
    border-right-width: 0;

    &:last-child {
      border-right-width: 1px;
    }
  }
}

//Vertical Button groups Style
.btn-group-vertical {

	> .btn {

		&.btn-secondary {

			&:hover, 
			&:focus {
				box-shadow: none;
			}

			&:active,
			&.active {
				box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.06);
			}
		}

		&.btn-primary {
			@include colored-vertical-btn-group($btn-primary-bg);
		}

		&.btn-success {
			@include colored-vertical-btn-group($btn-success-bg);
		}

		&.btn-warning {
			@include colored-vertical-btn-group($btn-warning-bg);
		}

		&.btn-danger {
			@include colored-vertical-btn-group($btn-danger-bg);
		}
	}
}

//Input group
.input-group-btn, .input-group-prepend, .input-group-append {

	> .btn {

		& + .dropdown-toggle {
			padding-left: 9px;
			padding-right: 9px;
		}
	}
}