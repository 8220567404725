/*------------------------------------------------------------------
  [Collapse cards style]
*/
.accordion {
	margin-bottom: 40px;
	padding: 0;
	
	.card {
		margin: 0;
		padding: 0;
		border-radius: 3px;

		& + .card {
			margin-top: .384rem;
		}
	}

	.card-header {
		padding: 0;
		margin: 0;
		border-radius: 3px;

		button {
			font-size: 1.385rem;
    	font-weight: 300;
			padding: 1.385rem 1.692rem;
			display: block;
			color: theme-color("primary");
			width: 100%;
			color: $body-color;
			background-color: transparent;
			text-align: left;

			&.btn {
				box-shadow: none;
				border: 0;

				&:focus {
					background-color: rgba(0,0,0,0.04);
				}		
			}

			.icon {
				transition: transform 200ms ease-in-out;
				font-size: 1.923rem;
				vertical-align: middle;
				position: relative;
				top: -1px;
				display: inline-block;
				margin-right: 10px;
			}

			&[aria-expanded='true'] {
				color: theme-color("primary");

				.icon {
					transform: rotate(90deg);
				}
			}
		}
	}

	.card-body {
		padding: 0 1.538rem 1.538rem 1.538rem;
		border-top: 0;
		line-height: 22px;
		border-radius: 0 0 3px 3px;
	}
}

//Colored Full Cards
.accordion-color {

	.card {

		.card-header {

			button {
				color: theme-color("light");

				&[aria-expanded='true'] {
					color: theme-color("light");
				}
			}
		}
	}
}
