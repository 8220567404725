.widget.widget-map{

	.map-container{

		> div{
			height: 229px;
		}
	}
}

