//Full colored modal
@mixin dropdown-color($color) {
  border-color: transparent;
  background-color: $color;

  &.dropdown-menu {

    //First level
    > .dropdown-item {
      color: theme-color("light");

      &:focus, &:active {
        color: theme-color("light");
      }

      &:hover {
        background-color: darken($color, 5%);
        color: theme-color("light");
      }

      .icon {
        color: theme-color("light");
      }
    }

    .dropdown-tools {
      
      .btn-group {
        
        .btn {
          background-color: transparent;
          color: theme-color("light");
          border-color: lighten($color, 18%);
          border-width: 2px;
          
          &:hover {
            border-color: theme-color("light");
            box-shadow: none;
            background-color: rgba(255, 255, 255, 0.1);

            & + .btn {
              border-left-color: transparent; 
            }
          }

          &:active,
          &.active {
            background-color: theme-color("light");
            color: $color;
            border-color: transparent;
            border-width: 2px;
            box-shadow: $btn-box-shadow-base;
          }

          &:focus {
            box-shadow: $btn-box-shadow-base;
          }
        }
      }
    }
  }

  //Divider Line
  .dropdown-divider {
    border-color: darken($color, 5%);
  }
}
