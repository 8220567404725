/*------------------------------------------------------------------
  [User timeline widget]
*/
.user-timeline{
	padding: 0;
	margin: 5px 0 15px;
	list-style: none;
	position: relative;

	&:before{
		content: "";
		position: absolute;
		left: 7px;
		top: 2px;
		height: 100%;
		width: 2px;
		background-color: theme-color("primary");
	}

	&:after{
		content: "";
		position: absolute;
		left: 4px;
		bottom: -2px;
		height: 8px;
		width: 8px;
		background-color: theme-color("primary");
		border-radius: 50%;
	}

	> li{
		display: block;
		position: relative;
		padding-left: 30px;
		padding-bottom: 27px;

		&:before{
			content: "";
			position: absolute;
			left: 0;
			top: 2px;
			height: 16px;
			width: 16px;
			background-color: theme-color("light");
			border-radius: 50%;
			border: 1px solid theme-color("primary");
		}

		&.latest{

			&:before{
				background-color: theme-color("primary");
			}
		}
	}

	&-date{
		text-transform: uppercase;
		color: lighten($body-color, 20%);
		margin-bottom: 8px;
	}

	&-title{
		font-weight: $font-weight-medium;
	}

	&-compact{

		.user-timeline-date{
			float: right;
			text-transform: capitalize;
			margin-bottom: 0;
			line-height: 22px;
		}

		.user-timeline-title{
			font-size: 1.385rem;
			font-weight: 300;
		}

		.user-timeline-description{
			color: lighten($body-color, 20%);
			margin-top: 4px;
		}

		&:before{
			background-color: $warning-color-text;
		}

		&:after{
			background-color: $warning-color-text;
		}

		> li{
			padding-bottom: 35px;

			&:last-child{
				padding-bottom: 25px;
			}

			&:before{
				border: 1px solid $warning-color-text;
			}

			&.latest{

				&:before{
					background-color: $warning-color-text;
				}
			}
		}
	}
}