/*------------------------------------------------------------------
  [Progress bars]
*/
.progress {
	height: $progress-line-height;
	box-shadow: none;
}

.progress-bar{
	box-shadow: none;
	line-height: $progress-line-height;
	background-color: $left-sidebar-progress-bar-color;
}