/*------------------------------------------------------------------
	[Main wrapper]
*/
.be-wrapper{
	min-height: 100%;
	padding-top: $top-header-height + 1px;

	@include phone {
		overflow-x: hidden;
	}

	@media #{$print} {
		padding-top: 0;
	}

	@include non-responsive{
		&.be-boxed-layout {
			max-width: $boxed-layout-width;
	    position: relative;
	    margin: 0 auto;
	    overflow: hidden;
	    background-color: lighten($body-bg, 1%);
	    display: flex;
	    box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.05);
		}
	}
}