/*------------------------------------------------------------------
  [Error pages]
*/
.be-error{
	
	.error-container{
		text-align: center;
		margin-top: 100px;

		@include tablet  {
			margin-top: 50px;
		}
	}

	.error-number{
		display: block;
		font-size: 11.54rem;
		font-weight: $font-weight-bold;
		line-height: 240px;
		margin-bottom: 30px;
		max-height: 240px;

		&:after {
			padding-top: 0.25%;
			display: block;
			content: '';
		}

		@include tablet {
			padding: 20px 0;
			font-size: 6.923rem;
			margin-bottom: 10px;
			line-height: 100px;
		}
	}

	.error-description{
		font-size: 2.308rem;
		font-weight: $font-weight-light;
		line-height: 32px;
		margin-bottom: 30px;

		@include tablet {
			font-size: 1.462rem;
		}
	}

	.error-goback-text{
		font-size: 1.692rem;
		font-weight: $font-weight-light;
		margin-bottom: 30px;
		margin-top: 15px;

		@include tablet {
			font-size: $font-size-lg;
		}
	}

	.error-goback-button{
		margin-bottom: 30px;


		.btn{
			min-width: 153px;
			text-align: center;
		}
	}
}

/*------------------------------------------------------------------
  [Page 404]
*/
.be-error-404{

	.error-container{

		.error-number{
			background: url("#{$img-path}/404-bg.png") no-repeat center center;
			background-size: contain;

			@include phone {
				padding: 0;
				font-size: 5.385rem;
			}

			@media (max-width: 340px){
				font-size: 3.846rem;
			}

			@media (max-width: 295px){
				font-size: 3.077rem;
			}
		}
	}
}