/*------------------------------------------------------------------
  [Forms]
*/
/*------------------------------------------------------------------
  [Switch button]
*/
@mixin switch-button-size($size-base) {
  height: $size-base;
  width: $size-base + 33px;
  line-height: $size-base - 4px;

  label {
    height: $size-base - 8px;
    width: $size-base - 8px;

    &:before {
      line-height: $size-base + 1px;
    }
  }
}

@mixin switch-button-color($active-color, $off-color: darken(theme-color("light"), 30%)) {
  background-color: $off-color;

  label {
    border-color: darken($off-color, 3%);
  }

  input[type="checkbox"]:checked + span { 
    background-color: $active-color;

    label {
      border-color: darken($active-color, 5%);
    }
  }

  input[type="checkbox"]:checked:focus + span {

    label:before {
      box-shadow: 0 0 0 $btn-focus-width rgba($active-color, .5);
    }
  }
}

.switch-button {
  display: inline-block;
  border-radius: 50px;
  background-color: darken(theme-color("light"), 30%);
  width: 60px;
  height: 27px;
  padding: 4px;
  position: relative;
  vertical-align: middle;
  
  input[type="checkbox"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  
  label {
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #FFF inset;
    background-color: theme-color("light");
    margin: 0;
    height: 19px;
    width: 19px;
    z-index: 1;
    display: inline-block;
    cursor: pointer;
    background-clip: padding-box;
    
    &:before {
      position: absolute;
      font-size: .8462rem;
      font-weight: 600;
      z-index: 0;
      content: "OFF";
      right: 0;
      display: block;
      width: 100%;
      height: 100%;
      line-height: 27px;
      top: 0;
      text-align: right;
      padding-right: 10px;
      color: theme-color("light");
      border-radius: 50px;
    } 								    
  }
  
  input[type="checkbox"]:checked + span {
    border-radius: 50px;
    background-color: theme-color("primary");
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 4px;
    
    label {
      float: right; 
      border-color: darken(theme-color("primary"), 5%);
    
      &:before {
        position: absolute;
        font-size: .8462rem;
        font-weight: 600;
        z-index: 0;
        content: "ON";
        color: theme-color("light");
        left: 0;
        text-align: left;
        padding-left: 10px;
      }
    }
  }

  input[type="checkbox"]:focus + span {

    label:before {
      box-shadow: 0 0 0 $btn-focus-width rgba(theme-color("secondary"), .3);
    }
  }

  input[type="checkbox"]:checked:focus + span {

    label:before {
      box-shadow: 0 0 0 $btn-focus-width rgba(theme-color("primary"), .5);
    }
  }
 
  //Yes-No labels
  &.switch-button-yesno{
    label{
 
      &:before{
        content: "NO";
      }
    }
 
    input[type="checkbox"]:checked + span{
 
      label{
        &:before{
          content: "YES";
        }
      }
    }
  }

  //Sizes
  &.switch-button-xs{
    @include switch-button-size(20px);
    width: 50px;
  }

  &.switch-button-sm{
    @include switch-button-size(24px);
  }

  &.switch-button-lg{
    @include switch-button-size(30px);
    width: 65px;
  }

  //Color styles
  &.switch-button-success{
    @include switch-button-color(theme-color("success"));
  }
  
  &.switch-button-warning{
    @include switch-button-color(theme-color("warning"));
  }

  &.switch-button-danger{
    @include switch-button-color(theme-color("danger"));
  }
}
