/* Effect 6: side fall */
.#{$modal-prefix}-effect-6.#{$modal-prefix}-container {
	-webkit-perspective: 1300px;
	-moz-perspective: 1300px;
	perspective: 1300px;
}

.#{$modal-prefix}-effect-6 .#{$modal-prefix}-content {
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: translate(30%) translateZ(600px) rotate(10deg); 
	-moz-transform: translate(30%) translateZ(600px) rotate(10deg);
	-ms-transform: translate(30%) translateZ(600px) rotate(10deg);
	transform: translate(30%) translateZ(600px) rotate(10deg); 
	opacity: 0;
}

.#{$modal-prefix}-show.#{$modal-prefix}-effect-6 .#{$modal-prefix}-content {
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	-webkit-transform: translate(0%) translateZ(0) rotate(0deg);
	-moz-transform: translate(0%) translateZ(0) rotate(0deg);
	-ms-transform: translate(0%) translateZ(0) rotate(0deg);
	transform: translate(0%) translateZ(0) rotate(0deg);
	opacity: 1;
}