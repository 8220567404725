/* Effect 2: Slide from the right */
.#{$modal-prefix}-effect-2 .#{$modal-prefix}-content {
	-webkit-transform: translateX(20%);
	-moz-transform: translateX(20%);
	-ms-transform: translateX(20%);
	transform: translateX(20%);
	opacity: 0;
	-webkit-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
	-moz-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
	transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.#{$modal-prefix}-show.#{$modal-prefix}-effect-2 .#{$modal-prefix}-content {
	-webkit-transform: translateX(0);
	-moz-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
	opacity: 1;
}