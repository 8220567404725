//
// Popovers
// --------------------------------------------------

.popover {
  font-size: $font-size-base;
  box-shadow: 0 5px 10px rgba(0,0,0,.2);

  .popover-header {
    margin: 0;
  }

  &.bottom > .arrow {
    border-bottom-color: darken($popover-bg, 3%);;
    top: -10px;

    &:after{
      background-color: transparent;
      top: -2px;
      margin-left: -10px;
      border-bottom-color:$popover-border-color;
      border-top-width: 1px;
      z-index: -1;
    }
  }
}