/*------------------------------------------------------------------
  [Custom pages]
*/
/*------------------------------------------------------------------
  [Login]
*/

//Login container for remember me and forgot password
.row {

	&.login-tools {
		padding: 15px 0 0;
  	margin-bottom: 16px;
	}
}

//Login forgot password link
.login-forgot-password {
	line-height: 2.1;
	text-align: right;
}

//Login submit
.login-submit, .row.login-submit {
	padding: 19px 0 0;
	margin-bottom: $form-group-margin-bottom - .1538rem;

	.btn {
		width: 100%;
	}

	//Reduce bs col padding
	> div {
		
		&:first-child {
			padding-right: 10px;
		}

		&:last-child {
			padding-left: 10px;
		}
	}
}