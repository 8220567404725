/*------------------------------------------------------------------
  [Parsley]
*/
.parsley-errors-list.filled{
	margin-top: 10px;
	margin-bottom: 0;
	padding: 15px 20px;
	position: relative;
	background-color: $parsley-error-bg;
	color: theme-color("light");
	
	&:after{
		content: "";
		display: inline-block;
		position: absolute;
		top: -6px;
		left: 20px;
		@include triangle(top, $parsley-error-bg, 7px);
	}

	> li{
		list-style: none;
	}
}