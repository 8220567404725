/*------------------------------------------------------------------
  [Summernote]
*/
.note-editor.note-frame{
	margin-bottom: 0;
	border-width: 0;
	box-shadow: none;

	.note-toolbar{
		margin: 0;
		padding-bottom: 5px;

		.btn{

			.fa{
				font-size: 1.077rem;
			}
		}

		> .btn-group{
			margin-left: 0;
	    margin-right: 5px;
	    margin-top: 5px;

    	.btn-light {
    		border-color: $input-border-color;

    		&:hover {
			    border-color: #bdbdbd;
    		}

			  &:not([disabled]):not(.disabled):active,
			  &:not([disabled]):not(.disabled).active,
			  .show > &.dropdown-toggle {
			    border-color: darken($btn-secondary-border, 13%);
			  }

    		&.dropdown-toggle {
					&:after {
						display: inline-block;
					}
				}

				+ .dropdown-menu {
				
					blockquote {
						padding: 10px 20px;
					}
				}
    	}
		}

		.note-color .dropdown-toggle{
			padding-left: 5px;
    	width: 20px;
		}
	}

	.note-statusbar{
		background-color: darken(theme-color("light"), 3.5%);

		.note-resizebar{
			height: auto;
			padding-top: 3px; 
			padding-bottom: 4px; 
		}
	}

	.note-editable{
		border: 1px solid $input-border-color;
		padding: 10px 12px;
		margin-top: 6px;
		border-radius: 0;
	}

	.note-editing-area{
		
		.note-editable{
			color: $body-color;
		}
	}
}