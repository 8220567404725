/* Effect 5: fall */
.#{$modal-prefix}-effect-5.#{$modal-prefix}-container {
	-webkit-perspective: 1300px;
	-moz-perspective: 1300px;
	perspective: 1300px;
}

.#{$modal-prefix}-effect-5 .#{$modal-prefix}-content {
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: translateZ(600px) rotateX(20deg); 
	-moz-transform: translateZ(600px) rotateX(20deg); 
	-ms-transform: translateZ(600px) rotateX(20deg); 
	transform: translateZ(600px) rotateX(20deg); 
	opacity: 0;
}

.#{$modal-prefix}-show.#{$modal-prefix}-effect-5 .#{$modal-prefix}-content {
	-webkit-transition: all 0.3s ease-in;
	-moz-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	-webkit-transform: translateZ(0px) rotateX(0deg);
	-moz-transform: translateZ(0px) rotateX(0deg);
	-ms-transform: translateZ(0px) rotateX(0deg);
	transform: translateZ(0px) rotateX(0deg); 
	opacity: 1;
}