/* Effect 10: 3D sign */
.#{$modal-prefix}-effect-10.#{$modal-prefix}-container {
	-webkit-perspective: 1300px;
	-moz-perspective: 1300px;
	perspective: 1300px;
}

.#{$modal-prefix}-effect-10 .#{$modal-prefix}-content {
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: rotateX(-60deg);
	-moz-transform: rotateX(-60deg);
	-ms-transform: rotateX(-60deg);
	transform: rotateX(-60deg);
	-webkit-transform-origin: 50% 0;
	-moz-transform-origin: 50% 0;
	transform-origin: 50% 0;
	opacity: 0;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.#{$modal-prefix}-show.#{$modal-prefix}-effect-10 .#{$modal-prefix}-content {
	-webkit-transform: rotateX(0deg);
	-moz-transform: rotateX(0deg);
	-ms-transform: rotateX(0deg);
	transform: rotateX(0deg);
	opacity: 1;
}