/* Effect 4: Newspaper */
.#{$modal-prefix}-effect-4 .#{$modal-prefix}-content {
	-webkit-transform: scale(0) rotate(720deg);
	-moz-transform: scale(0) rotate(720deg);
	-ms-transform: scale(0) rotate(720deg);
	transform: scale(0) rotate(720deg);
	opacity: 0;
}

.#{$modal-prefix}-show.#{$modal-prefix}-effect-4 ~ .#{$modal-prefix}-overlay,
.#{$modal-prefix}-effect-4 .#{$modal-prefix}-content {
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}

.#{$modal-prefix}-show.#{$modal-prefix}-effect-4 .#{$modal-prefix}-content {
	-webkit-transform: scale(1) rotate(0deg);
	-moz-transform: scale(1) rotate(0deg);
	-ms-transform: scale(1) rotate(0deg);
	transform: scale(1) rotate(0deg);
	opacity: 1;
}
