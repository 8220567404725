/* Effect 12:  Just me */
.#{$modal-prefix}-effect-12 .#{$modal-prefix}-content {
	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	transform: scale(0.8);
	opacity: 0;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.#{$modal-prefix}-show.#{$modal-prefix}-effect-12 ~ .#{$modal-prefix}-overlay {
	background: #000;
} 

.#{$modal-prefix}-show.#{$modal-prefix}-effect-12 .#{$modal-prefix}-content {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 1;
}