/* Effect 7:  slide and stick to top */
.#{$modal-prefix}-effect-7{
	top: 0;
}

.#{$modal-prefix}-effect-7 .#{$modal-prefix}-content {
	margin-top: 0;
	-webkit-transform: translateY(-200%);
	-moz-transform: translateY(-200%);
	-ms-transform: translateY(-200%);
	transform: translateY(-200%);
	-webkit-transition: all .3s;
	-moz-transition: all .3s;
	transition: all .3s;
	opacity: 0;
}

.#{$modal-prefix}-show.#{$modal-prefix}-effect-7 .#{$modal-prefix}-content {
	-webkit-transform: translateY(0%);
	-moz-transform: translateY(0%);
	-ms-transform: translateY(0%);
	transform: translateY(0%);
	border-radius: 0 0 3px 3px;
	opacity: 1;
}