/* Effect 14:  3D Rotate from bottom */

.#{$modal-prefix}-effect-14.#{$modal-prefix}-container {
	-webkit-perspective: 1300px;
	-moz-perspective: 1300px;
	perspective: 1300px;
}

.#{$modal-prefix}-effect-14 .#{$modal-prefix}-content {
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: translateY(100%) rotateX(90deg);
	-moz-transform: translateY(100%) rotateX(90deg);
	-ms-transform: translateY(100%) rotateX(90deg);
	transform: translateY(100%) rotateX(90deg);
	-webkit-transform-origin: 0 100%;
	-moz-transform-origin: 0 100%;
	transform-origin: 0 100%;
	opacity: 0;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.#{$modal-prefix}-show.#{$modal-prefix}-effect-14 .#{$modal-prefix}-content {
	-webkit-transform: translateY(0%) rotateX(0deg);
	-moz-transform: translateY(0%) rotateX(0deg);
	-ms-transform: translateY(0%) rotateX(0deg);
	transform: translateY(0%) rotateX(0deg);
	opacity: 1;
}