/*------------------------------------------------------------------
  [Perfect Scrollbar]
*/
/*Perfect Scrollbar*/

.ps {

  &:hover, &.ps--scrolling-y {

    > .ps__rail-y {
      background-color: transparent;  

      &:hover {
        width: $scroller-size;
        background-color: #eeeeee;
      }

      > .ps__thumb-y{
        right: 0;
        width: $scroller-size;
        border-radius: 3px;
        background-color: rgba(0,0,0, 0.25);
      }        
    }
  }

  > .ps__rail-y{
    width: $scroller-size;
    background-color: rgba(0,0,0, 0.1);

    > .ps__thumb-y{
      right: 0;
      width: $scroller-size;
      border-radius: 3px;
      background-color: rgba(0,0,0, 0.25);
    }
  }
}