/*------------------------------------------------------------------
  [Forgot password]
*/
.splash-container.forgot-password{

	.card{
		
		.card-header{
			margin-bottom: 5px;
		}
	}
}