/*------------------------------------------------------------------
  [Tile widgets]
*/
.widget.widget-tile{
	padding: 24px 20px;
	margin-bottom: $widget-margin-bottom;
	display: table;
	table-layout: fixed;
	width: 100%;

	.data-info{
		display: table-cell;
		text-align: right;

		.value{
			font-size: 1.693rem;
			font-weight: $font-weight-light;
		}

		.indicator{
			font-size: 1.923rem;
			margin-right: 7px;
			line-height: 24px;
			vertical-align: middle;

			&-equal{
				color: theme-color("primary");
			}

			&-positive{
				color: theme-color("success");
			}

			&-negative{
				color: theme-color("danger");
			}
		}

		.desc{
			font-size: 1.077rem;
			line-height: 18px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.chart{
		width: 85px;
		min-height: 45px;
		padding: 5px 0;
		display: table-cell;
		vertical-align: middle;
	}

	&.widget-tile-wide{
		display: table;
		width: 100%;
		
		> div{
			display: table-cell;
			width: 50%;
			vertical-align: middle;
		}

		.data-info{
			vertical-align: top;

			.title{
				font-size: 1.231rem;
		    line-height: 22px;
		    margin-bottom: 2px;
			}

			.desc{
				color: darken(theme-color("light"), 50%);
				font-size: .9231rem;
			}
		}

		.tile-value{
			text-align: right;

			> span{
				display: block;
				padding-right: 10px;
				font-weight: 300;
				font-size: 2.385rem;
			}
		}

		.icon{
			float: none;
			line-height: 43px;
			vertical-align: top;
			margin-right: 5px;
		}
	}
}

